<template lang="">
    <b-card no-body>
        <div class="m-2">

            <!-- Table Top -->
            <b-row>
                <!-- Per Page -->
                <!-- <b-col
                    cols="2"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                >
                    <label>Afficher</label>
                    <v-select
                    v-model="perPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="perPageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block mx-50"
                    />
                    <label>interventions</label>
                </b-col> -->

                <b-col
                    cols="6"
                    class="d-flex align-items-center justify-content-center mb-1 mb-md-0"
                >
                    
                </b-col>

                

                <b-col cols="12" md="6">
                    <div class="d-flex align-items-center justify-content-end">
                        <vs-button type="line" line-origin="left" color="danger" class="mr-5"  @click="filterFildes()">
                            <feather-icon icon="FilterIcon" svgClasses="h-4 w-4"  />  Filtrer
                        </vs-button>
                        <b-button variant="primary":to="{ name: 'interventions-add'}">
                            <span class="text-nowrap">Créer intervention</span>
                        </b-button>
                    </div>
                </b-col>
            </b-row>
            
            <b-row v-if="filter">
                <b-col cols="12" md="2">
                    <b-form-group label="Nom & prénom" label-for="nom">
                        <b-form-input id="nom" v-model="f_contact"  autofocus trim placeholder="Nom & prénom"/>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="2">
                    <b-form-group label="Code postal" label-for="zipcode">
                        <b-form-input id="zipcode" v-model="f_zipcode"  autofocus trim placeholder="Code postal"/>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="2">
                    <b-form-group label="Catégorie" label-for="service">
                        <v-select
                            v-model="f_service_id"
                            :options="serviceChoices"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="name"

                            :reduce="service => service.id"
                            input-id="service"
                        />
                    </b-form-group>
                </b-col>

                 <b-col cols="12" md="2">
                    <b-form-group label="Etat" label-for="etat">
                        <v-select
                            v-model="f_etat"
                            :options="Status_choices"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="label"
                            input-id="etat"
                        />
                    </b-form-group>
                </b-col>
                <b-col>
                    <div class="vs-component vs-con-input-label vs-input  w-full vs-input-primary">
                        <label for="" class="vs-input--label" >Date de création</label><br>
                        <date-range-picker
                            ref="picker"
                            class="w-full"
                            :locale-data="{ direction: 'ltr',
                                        format: 'mm/dd/yyyy',
                                        separator: ' - ',
                                        applyLabel: 'Valider',
                                        cancelLabel: 'Annuler',
                                        weekLabel: 'W',
                                        customRangeLabel: 'Custom Range',
                                        daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
                                        monthNames: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                                        firstDay: 0
                                        }"
                            v-model="dateRange"
                            :ranges="{
                                'Today': [today, new Date(today.getFullYear(), today.getMonth(), today.getDate(), 11, 59, 59, 999)],
                                'Yesterday': [new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1), new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1, 11, 59, 59, 999)],
                                'This month': [new Date(today.getFullYear(), today.getMonth(), 1), new Date(today.getFullYear(), today.getMonth() + 1, 0, 11, 59, 59, 999)],
                                'Last month': [new Date(today.getFullYear(), today.getMonth() - 1, 1),new Date(today.getFullYear(), today.getMonth(), 0, 11, 59, 59, 999)],
                                'This year': [new Date(today.getFullYear(), 0, 1),new Date(today.getFullYear(), 11, 31, 11, 59, 59, 999)],
                                'Last year': [new Date(today.getFullYear() - 1, 0, 1),new Date(today.getFullYear() - 1, 11, 31, 11, 59, 59, 999)]
                            }"
                        >
                            <template v-slot:input="picker" style="min-width: 350px;">
                            <feather-icon icon="CalendarIcon" svgClasses="h-4 w-4" />
                                {{ picker.startDate | moment("DD-MMMM-YYYY") }} - {{ picker.endDate | moment("DD-MMMM-YYYY") }}
                            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />

                            </template>
                            <template #ranges="ranges">
                            <div class="ranges">
                                <ul>
                                <li v-for="(range, name) in ranges.ranges" :key="name" @click="ranges.clickRange(range)">
                                    <b v-if="name == 'Today'">Aujourd'hui</b>
                                    <b v-if="name == 'Yesterday'"> Hier</b>
                                    <b v-if="name == 'This month'"> Ce mois</b>
                                    <b v-if="name == 'This year'"> Cette année</b>
                                    <b v-if="name == 'Last month'">Mois dérnier</b>
                                    <b v-if="name == 'Last year'">Année dérnière</b>
                        
                                </li>
                                </ul>
                            </div>
                            </template>
                        </date-range-picker>
                    </div>
                </b-col>

            </b-row>

        </div>

        <b-table
            id="contacts"
            class="position-relative"
            responsive
            :fields="contactColumns"
            :items="filtredContacts"
            :current-page="currentPage"
            :per-page="perPage"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="Aucune interventions trouvé"
            :sort-desc.sync="isSortDirDesc"
            @row-clicked="myRowClickHandler"
        >

            <template #cell(urgence)="data">
            <b-badge pill variant="danger" v-if="data.item.urgence">Urgent</b-badge>
            <b-badge pill variant="primary" v-else>Rendez-vous</b-badge>
            </template>

            <template #cell(nbr_affectation)="data">
                {{ data.item.artisans.length }}
            </template>

            <template #cell(nbr_achat)="data">
                {{ achat_nbrs(data.item.artisans) }}
            </template>

            <template #cell(actions)="data">
                <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                >

                    <template #button-content>
                    <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                    />
                    </template>
                    <b-dropdown-item :to="{ name: 'interventions-view', params: { id: data.item.id } }">
                        <feather-icon icon="FileTextIcon" />
                        <span class="align-middle ml-50">Détails</span>
                    </b-dropdown-item>

                    <b-dropdown-item :to="{ name: 'interventions-edit', params: { id: data.item.id } }"> 
                        <feather-icon icon="EditIcon" />
                        <span class="align-middle ml-50">Éditer</span>
                    </b-dropdown-item>
                </b-dropdown>
            </template>

        </b-table>
        <div class="mx-2 mb-2">
            <b-row>
    
            <!-- Pagination -->
            <b-col
                cols="12"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
    
                <b-pagination
                v-model="currentPage"
                :total-rows="totalDemandes"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                >
                <template #prev-text>
                    <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                    />
                </template>
                <template #next-text>
                    <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                    />
                </template>
                </b-pagination>
    
            </b-col>
    
            </b-row>
        </div>
    </b-card>
</template>
<script>

    import {
        BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BFormGroup,
        BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip, BCardTitle, BCardBody, BFormCheckbox 
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import { mapGetters, mapActions } from "vuex"
    import Ripple from 'vue-ripple-directive'
    import { BBreadcrumb} from 'bootstrap-vue'

    import DateRangePicker from 'vue2-daterange-picker'
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
    import { moment } from 'vue-moment';


    export default {
        components: {
            BCard,
            BRow,
            BCol,
            BFormInput,
            BButton,
            BTable,
            BMedia,
            BAvatar,
            BLink,
            BBadge,
            BDropdown,
            BDropdownItem,
            BPagination,
            BCardBody,
            BCardTitle,
            BFormCheckbox,
            vSelect,
            BFormGroup,
            BBreadcrumb,
            DateRangePicker,
            moment
        },

        data() {

            var date = new Date();
            let startDate = new Date(date.getFullYear(), date.getMonth(), 1);
            let endDate = new Date();

            let today = new Date()
            today.setHours(0, 0, 0, 0)

            return {
                sortBy: 'id',
                isSortDirDesc : true,
                perPage : 10,
                perPageOptions : [10, 25, 50, 100],
                currentPage : 1,
                totalDemandes: 0,
                contactColumns : [
                    { label: 'Nom & prénom', key: 'contact.nom', sortable: true },
                    { label: 'Code postal', key: 'contact.zipcode', sortable: true },
                    { label: 'Catégorie', key: 'service.service.name', sortable: true },
                    { label: 'Date création', key: 'created_date', sortable: true },
                    { label: 'Date intervention', key: 'date', sortable: true },
                    { label: 'Etat d\'ugence', key: 'urgence', thClass: "text-center", tdClass: "text-center", sortable: true },
                    { label: 'Côut', key: 'cout', sortable: true },
                    { label: 'Envoi', key: 'nbr_affectation', thClass: "text-center", tdClass: "text-center", sortable: true },
                    { label: 'Achat', key: 'nbr_achat', thClass: "text-center", tdClass: "text-center", sortable: true },
                    { label: 'Actions', key: 'actions', thClass: 'text-center', tdClass: 'text-center' },
                ],
                items: [
                    {
                        text: 'Dashboard',
                    },
                    {
                        text: 'Interventions',
                        active: true,
                    }
                ],

                filter : false,
                f_contact:"",
                f_zipcode:"",
                f_service_id: null,
                f_etat: "",
                Status_choices : [
                    {label: 'Urgent', value: 1},
                    {label: 'Rendez-vous', value: 0},
                ],
                
                dateRange: {startDate, endDate},

                today: today

            }
        },

        computed: {
            ...mapGetters('demandes', {'demandes' : 'GET_ITEMS'}),
            ...mapGetters('services', {'serviceChoices' : 'GET_ITEMS'}),

            filtredContacts() {
                let demandes = null

                if(this.f_contact.trim() == ""){
                    demandes = this.demandes
                }else{
                    demandes = this.demandes.filter(item => {
                        console.log("item:",item);
                        return item.contact.nom.trim().toLowerCase().includes(this.f_contact.toLowerCase())
                    })
                }

                var zipcode=""
                if(this.f_zipcode.trim() != ""){
                    demandes = demandes.filter(item => {
                        zipcode = item.contact.zipcode+"";
                        return zipcode.includes(this.f_zipcode)
                    })
                }

                if(this.f_service_id != null) {
                    demandes = demandes.filter(item => {
                        return item.service.service.id == this.f_service_id
                    })
                }

                if(this.f_etat != null) {
                    if(this.f_etat.value != null){
                        demandes = demandes.filter(item => {
                            return this.f_etat.value == item.urgence
                        })
                    }
                }

                // demandes = demandes.filter(item => {
                //     return this.format_date(item.created_date) >= this.format_date(this.dateRange.startDate) && this.format_date(item.created_date) <= this.format_date(this.dateRange.endDate)
                // })



                this.totalDemandes = demandes.length
                return demandes
            },

     

            

        },

        methods: {
            ...mapActions('demandes', {'fetchDemandes' : 'fetchItems'}),
            ...mapActions('services', {'fetchServices' : 'fetchItems'}),

            myRowClickHandler(record, index) {
                this.$router.push({ name: 'interventions-view', params: { id: record.id } });
            },

            achat_nbrs(array) {
                return array.filter(item => {
                    return item.date_achat != null
                }).length
            },
            filterFildes() {
                this.filter = !this.filter
                if(!this.filter) {
                    this.f_contact = ""
                    this.f_zipcode = ""
                    this.f_service_id = null

                    var date = new Date();
                    let startDate = new Date(date.getFullYear(), date.getMonth(), 1);
                    let endDate = new Date();
                    this.dateRange = {startDate, endDate}
                }
            },

            format_date(date) {
                var d = new Date(date),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;

                return [year, month, day].join('-');
            },
        },

        created() {
            this.fetchDemandes()
            this.fetchServices()
            console.log("this.fetchDemandes():",this.fetchDemandes());
            
        },

        mounted() {
            
        },

        watch: {
           
          
        },

        directives: {
            'b-tooltip': VBTooltip,
            Ripple
        },
        
    }
</script>

<style>
    .per-page-selector {
        width: 90px;
    }

    #contacts tr {
        cursor: pointer;
    }

    #contacts tbody tr:hover {
        background: #ecf3ff;
    }

</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>